'use client';
import { useEffect } from "react";
import RegisterUser from "../registerUser";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const FormRegisterUser = () => {


    return (
        <div id="information" className="container mx-auto px-5 md:px-8 grid grid-cols-1 md:grid-cols-2 gap-2 py-16 md:py-28 lg:py-30" aria-labelledby="contact-heading">
            <header className="text-left">
                <h2 id="contact-heading" className="text-4xl font-bold mb-4 mt-12 text-white" data-aos="fade-up" data-aos-delay="200">¿Quieres que hablemos?</h2>
                <p className="text-base leading-relaxed text-gray-400 md:mr-20" data-aos="fade-up" data-aos-delay="200">
                    Si tienes alguna pregunta o estás interesado en trabajar con nosotros.
                    No dudes en ponerte en contacto. Estamos aquí para ayudarte.
                </p>
            </header>

            <div className="md:p-6" data-aos="fade-up" data-aos-delay="200">
                <RegisterUser />
            </div>
        </div>
    )
};

export default FormRegisterUser;